import * as types from '../actions';
import {getApi} from "../services/httpService";

export const loginUserAction = (user) => {
  return {
    type: types.LOGIN_USER,
    user: user
  }
};

export function setCurrentUser(user) {
  return {
    type: types.SET_CURRENT_USER,
    user: user
  }
}

export function unsetCurrentUser() {
  return {
    type: types.UNSET_CURRENT_USER
  }
}


export function login(data) {
  return getApi().post('user/login', data)
}

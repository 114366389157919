import axios from 'axios';
import {REASON_CODES} from '../constants/reasonCodes';
import {LOGIN_TOKEN_NAME} from '../constants/appConstants'

axios.defaults.baseURL = process.env.REACT_APP_API_URL + process.env.REACT_APP_API_VERSION + '/';
axios.defaults.headers = {'X-Requested-With': 'XMLHttpRequest'};
axios.defaults.timeout = 20000;

export function setAuthorizationToken(token) {
  if (token) {
    axios.defaults.headers['Authorization'] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.Authorization;
  }
}

export const defaultErrorMessage = {
  succes: false,
  message: 'Opps! Something went wrong! Please contact adminstrator'
};


axios.interceptors.response.use(function (response) {
  // Do something with response data
  if (response.data && !response.data.success) {
    if (response.data.rc === REASON_CODES.AUTHORIZATION_ERROR) {
      localStorage.removeItem(LOGIN_TOKEN_NAME);
      window.location = '/';
    }
  }

  return response.data
}, function (error) {
   return Promise.reject(error);
});

/**
 *
 * @param options
 * @returns {AxiosInstance}
 */
export function getApi(options = {}) {
  return axios;
}

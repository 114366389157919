export const BUS_TYPES = [
  {value: 1, label: 'AC'},
  {value: 2, label: 'Non AC'},
];

export const BUS_LAYOUT_TYPES = [
  {value: 1, label: 'Layout 2:2 Front Door'},
  {value: 2, label: 'Layout 2:2 DOOR AFTER 3'},
];

export const BUS_LAYOUT_ID = {
  ID_2_2_FRONT_DOOR: 1,
  ID_2_2_DOOR_AFTER_3: 2
};

export const SEAT_NUMBER_TYPES = [
  {value: 1, label: 'Number, 1, 2, 3...'},
  {value: 2, label: 'Number, A1, A2, A3...'},
];

export const GENDER_TYPES = [
  {value: 1, label: 'Male'},
  {value: 2, label: 'Female'},
  {value: 3, label: 'Other'},
];

export const STUFF_TYPES = [
  {value: 1, label: 'DRIVER'},
  {value: 2, label: 'SUPERVISOR'},
  {value: 3, label: 'OPERATOR'},
];

export const LOGIN_TOKEN_NAME = 'jwtToken';

export const PAGINATION = {
  PER_PAGE: 20
};

export const STATUS_VALUES = {
  ENABLE: 1,
  DISABLE: 0
};

export const permissions = JSON.parse(localStorage.getItem("permissions"));


import * as types from '../actions';
import isEmpty from 'lodash/isEmpty'

const initialState = {
  isAuthenticated: false,
  user: {},
  validation: {
    isValid: true,
    message: '',
    fields: {}
  }
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.SET_CURRENT_USER:
      return {
        ...initialState,
        isAuthenticated: !isEmpty(action.user),
        user: action.user
      };
    case types.LOGIN_USER_VALIDATION_FAILED:
      return {
        ...initialState, validation: {
          isValid: false,
          message: action.data.message,
          fields: action.data.data
        }
      };
    case types.UNSET_CURRENT_USER:
      return initialState;
    case types.LOGIN_USER_ERROR:
      alert("Sorry! Something went wrong. Please contact administrator");
      break
    default:

      return state;
  }
};

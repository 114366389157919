import {put, call} from 'redux-saga/effects';

import * as types from '../actions'
import {login} from "../actions/authenticationActions";
import {REASON_CODES} from "../constants/reasonCodes";
import {LOGIN_TOKEN_NAME} from "../constants/appConstants";
import {setAuthorizationToken} from "../services/httpService";


export function* loginSaga(payload) {
  try {
    const response = yield call(login, payload.user);
    const data = response.data;
    if (data.token) {
      let jwt = require('jsonwebtoken');

      localStorage.setItem(LOGIN_TOKEN_NAME, data.token);
      localStorage.setItem("permissions", JSON.stringify(data.permissions));

      setAuthorizationToken(data.token);

      yield put({type: types.SET_CURRENT_USER, user: jwt.decode(data.token)});
    } else {
      if (data.rc && data.rc === REASON_CODES.VALIDATION_FAILED) {
        yield put({type: types.LOGIN_USER_VALIDATION_FAILED, data: data});
      }
    }
  } catch (error) {
    yield put({type: types.LOGIN_USER_ERROR, error})
  }
}
